


























































































































import { enumeracion } from "@/shared/dtos/enumeracion";
import enumeracionModule from "@/store/modules/enumeracion-module";
import DxScrollView from "devextreme-vue/scroll-view";
import DxSortable from "devextreme-vue/sortable";
import { Component, Vue } from "vue-property-decorator";
import { MessageService } from "@/shared/services/message-service";
import Xbtn from "@/components/buttons/x-btn.vue";
@Component({
  components: {
    DxScrollView,
    DxSortable,
    Xbtn,
  },
  $_veeValidate: { validator: "new" },
})
export default class TareasTipos extends Vue {
  public itemOrientation = "vertical";
  public dragDirection = "both";
  public dialogo: boolean = false;
  public id_cabezera = 7;
  public item_priority: enumeracion = new enumeracion({
    id: 0,
  });

  created() {
    this.get_enums();
    this.nuevo_item();
  }

  public get items() {
    return enumeracionModule.enumeraciones;
  }

  public get_enums() {
    enumeracionModule.getenumeraciones_fromtipo(this.id_cabezera);
  }
  public nuevo_item() {
    this.$validator.reset();
    this.item_priority = new enumeracion({
      id: 0,
      id_tipo_enumeracion: this.id_cabezera,
    });
  }

  public close() {
    this.dialogo = false;
    this.get_enums();
  }
  onDragStart(e: any) {
    e.itemData = this.items[e.fromIndex];
  }

  onReorder(e: any) {
    if (e.fromIndex === e.toIndex) {
      return;
    }
    this.items.splice(e.fromIndex, 1);
    this.items.splice(e.toIndex, 0, e.itemData);
    for (let i = 0; i < this.items.length; i++) {
      this.items[i].orden = i;
    }
    enumeracionModule
      .modificarenumeracion_lista(this.items)
      .then(() => this.get_enums());
  }

  eliminar(item: enumeracion) {
    MessageService.confirm(
      "¿Seguro que quiere eliminar la false?",
      "Eliminar",
      (x: boolean) => {
        if (x) {
          enumeracionModule
            .eliminarenumeracion(item)
            .then(() => this.get_enums());
        }
      }
    );
  }
  nuevo() {
    this.nuevo_item();
    this.dialogo = true;
  }
  public editar(item: enumeracion) {
    this.item_priority = item;
    this.dialogo = true;
  }

  public guardar(item: enumeracion) {
    this.$validator.validateAll().then((allOk) => {
      if (allOk) {
        if (item.id === 0) {
          item.orden = this.items.length + 1;
          enumeracionModule.guardarenumeracion(item).then(() => {
            this.get_enums();
            this.dialogo = false;
          });
        } else {
          enumeracionModule.modificarenumeracion(item).then(() => {
            this.get_enums();
            this.dialogo = false;
          });
        }
      }
    });
  }
}
